import React from 'react'
import AddExample from './AddExample'

function Sales () {
  return (
    <div>
      <h1>Sales</h1>
      <AddExample />
    </div>
  )
}

export default Sales
