/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:fb08dffc-ded5-4837-8324-f9eac1e4e8fa",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_P9PGX44uV",
    "aws_user_pools_web_client_id": "7e579rmln6kg5j505e5e9obfe8",
    "oauth": {}
};


export default awsmobile;
