import React, { useState, useEffect } from 'react'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import styled from 'styled-components'
import { Auth } from 'aws-amplify'

const Styles = styled.div`
  .navbar: {
    background-color: #222;
  }
  .navbar-brand .navbar-nav .nav-link {
    color: #bbb;
    &hover {
      color: white;
    }
  }
  .nav-link {
    font-family: 'Roboto', sans-serif;
    color: #fff !important;
  }
`

function Profile () {
  const [user, setUser] = useState(null)
  async function setCurrentUser () {
    const currentUser = await Auth.currentAuthenticatedUser()
    // console.log('USER', currentUser)
    setUser(currentUser)
  }
  useEffect(() => setCurrentUser(), [])
  // useEffect(() => setCurrentUser(), [user])

  async function signOut () {
    try {
      await Auth.signOut()
    } catch (error) {
      console.log('error signing out: ', error)
    }
  }
  if (!user) return null
  return (
    <NavDropdown title={user?.attributes?.email} id='basic-nav-dropdown'>
      <NavDropdown.Item onClick={() => signOut()}>Sign out</NavDropdown.Item>
    </NavDropdown>
  )
}

function Navigation () {
  return (
    <Styles>
      <Navbar bg='dark' expand='lg' variant='dark'>
        <Navbar.Brand href='/app'>ModMedS</Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='ml-auto'>
            <NavDropdown title='Transactions' id='basic-nav-dropdown'>
              <NavDropdown.Item href='/app/sales'>Sales</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title='References' id='basic-nav-dropdown'>
              <NavDropdown.Item href='/app/partners'>Partners</NavDropdown.Item>
              <NavDropdown.Item href='/app/currencies'>Currencies</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title='Reports' id='basic-nav-dropdown'>
              <NavDropdown.Item href='/app/soa'>SoA</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Profile />
        </Navbar.Collapse>
      </Navbar>
    </Styles>
  )
}
export default Navigation
