import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { EditingState } from '@devexpress/dx-react-grid'
import { Grid, Table, TableHeaderRow, TableColumnVisibility, TableEditRow, TableEditColumn } from '@devexpress/dx-react-grid-bootstrap4'
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css'
import { DataStore } from '@aws-amplify/datastore'
import { pick, head, keys, compose } from 'ramda'

import { Currency } from '../models'

function Currencies () {
  const columns = [
    { name: 'id', title: 'ID' },
    { name: 'name', title: 'Name' }
  ]

  const [show, setShow] = useState(false)
  const [deleted, setDeleted] = useState([])

  const handleShow = () => setShow(true)
  const handleCancel = () => setShow(false)
  const handleDelete = async () => {
    const toDelete = currencies[deleted[0]]
    await DataStore.delete(toDelete)
    setShow(false)
  }

  const [currencies, setCurrencies] = useState([])
  const [hiddenColumnNames] = useState(['id'])

  async function fetchCurrencies () {
    try {
      const currencies = await DataStore.query(Currency)
      setCurrencies(currencies)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchCurrencies()
    const subscription = DataStore.observe(Currency).subscribe(() => fetchCurrencies())
    return () => subscription.unsubscribe()
  }, [])

  const commitChanges = async ({ added, changed, deleted }) => {
    if (added) {
      const currency = added[0]
      await DataStore.save(new Currency(currency))
    }
    if (changed) {
      const changedIndex = compose(head, keys)(changed)
      const changedCurrency = pick(['id', 'name'])({ ...currencies[changedIndex], ...changed[changedIndex] })
      const original = await DataStore.query(Currency, changedCurrency.id)
      console.log('ORIGINAL', original)
      await DataStore.save(
        Currency.copyOf(original, updated => {
          updated.name = changedCurrency.name
        })
      )
    }
    if (deleted) {
      setDeleted(deleted)
      handleShow()
    }
  }

  return (
    <React.Fragment>
      <Grid rows={currencies} columns={columns}>
        <EditingState
          onCommitChanges={commitChanges}
        />
        <Table />
        <TableHeaderRow />
        <TableColumnVisibility defaultHiddenColumnNames={hiddenColumnNames} />
        <TableEditRow />
        <TableEditColumn showAddCommand showEditCommand showDeleteCommand />
      </Grid>
      <Modal show={show} onHide={handleCancel} animation={false} backdrop='static'>
        <Modal.Header closeButton>
          <Modal.Title>Delete Currency</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete currency?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
    
  )
}

export default Currencies
