import React, { useState } from 'react'
import { EditingState } from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn
} from '@devexpress/dx-react-grid-bootstrap4'
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css'

const generateRows = () => {
  return [
    {
      id: 1,
      user: {
        firstName: 'David',
        lastName: 'Brown'
      },
      dateOfBirth: '1970-01-01',
      position: 'Sales Assistant',
      city: 'Paris',
      car: {
        model: 'Nissan Altima'
      }
    }
  ]
}

const getRowId = row => row.id

const AddExample = () => {
  const [columns] = useState([
    {
      name: 'firstName',
      title: 'First Name',
      getCellValue: row => (row.user ? row.user.firstName : undefined)
    },
    {
      name: 'lastName',
      title: 'Last Name',
      getCellValue: row => (row.user ? row.user.lastName : undefined)
    },
    {
      name: 'car',
      title: 'Car',
      getCellValue: row => (row.car ? row.car.model : undefined)
    },
    { name: 'dateOfBirth', title: 'Date of birth', type: 'date' },
    { name: 'position', title: 'Position' },
    { name: 'city', title: 'City' }
  ])
  const [rows, setRows] = useState(generateRows())
  const [editingColumnExtensions] = useState([
    {
      columnName: 'firstName',
      createRowChange: (row, value) => ({ user: { ...row.user, firstName: value } })
    },
    {
      columnName: 'lastName',
      createRowChange: (row, value) => ({ user: { ...row.user, lastName: value } })
    },
    {
      columnName: 'car',
      createRowChange: (row, value) => ({ car: { model: value } })
    }
  ])

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows
    if (added) {
      const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0
      changedRows = [
        ...rows,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row
        }))
      ]
    }
    if (changed) {
      changedRows = rows.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row))
    }
    if (deleted) {
      const deletedSet = new Set(deleted)
      changedRows = rows.filter(row => !deletedSet.has(row.id))
    }
    setRows(changedRows)
  }

  return (
    <div className='card'>
      <Grid
        rows={rows}
        columns={columns}
        getRowId={getRowId}
      >
        <EditingState
          columnExtensions={editingColumnExtensions}
          onCommitChanges={commitChanges}
        />
        <Table />
        <TableHeaderRow />
        <TableEditRow />
        <TableEditColumn showAddCommand showEditCommand showDeleteCommand />
      </Grid>
    </div>
  )
}

export default AddExample
