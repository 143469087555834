import React from 'react'

function SoA () {
  return (
    <div>
      Statement of Accounts
    </div>
  )
}

export default SoA
