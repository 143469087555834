// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const PartnetCategory = {
  "CUSTOMER": "CUSTOMER",
  "SUPPLIER": "SUPPLIER"
};

const { Currency } = initSchema(schema);

export {
  Currency,
  PartnetCategory
};