import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Home from '../components/Home'
import Partners from '../components/Partners'
import Currencies from '../components/Currencies'
import SoA from '../components/SoA'
import Sales from '../components/Sales'

function Routes () {
  return (
    <Router basename='/app'>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/partners' component={Partners} />
        <Route exact path='/currencies' component={Currencies} />
        <Route exact path='/sales' component={Sales} />
        <Route exact path='/soa' component={SoA} />
      </Switch>
    </Router>
  )
}

export default Routes
