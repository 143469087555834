import React from 'react'
// import { Grid, Table, TableHeaderRow, TableColumnVisibility } from '@devexpress/dx-react-grid-bootstrap4'
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css'

// import { listPartners } from '../graphql/queries'
// import { API, graphqlOperation } from 'aws-amplify'
// const Partners = () => {
//   const columns = [
//     { name: 'id', title: 'ID' },
//     { name: 'name', title: 'Name' },
//     { name: 'type', title: 'Type' }
//   ]

//   const [hiddenColumnNames] = useState(['id'])

//   const [partners, setPartners] = useState([])

//   async function fetchPartners () {
//     try {
//       const response = await API.graphql(graphqlOperation(listPartners))
//       setPartners(response.data.listPartners.items)
//     } catch (error) {
//       console.log(error)
//     }
//   }

//   useEffect(() => {
//     fetchPartners()
//   }, [])

//   return (
//     <Grid rows={partners} columns={columns}>
//       <Table />
//       <TableHeaderRow />
//       <TableColumnVisibility defaultHiddenColumnNames={hiddenColumnNames} />
//     </Grid>
//   )
// }

const Partners = () => <div>Partners</div>

export default Partners
