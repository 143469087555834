import React from 'react'
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react'
import Navigation from './components/Navigation'
import Layout from './layouts/Layout'
import Router from './routes/Router'

const App = () => {
  return (
    <AmplifyAuthenticator>
      <AmplifySignIn hideSignUp slot='sign-in' headerText='ModMedS Sign In' usernameAlias='email' />
      <Layout>
        <Navigation />
        <Router />
      </Layout>
    </AmplifyAuthenticator>
  )
}

export default App
